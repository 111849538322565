import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import ApiAdapter from "../../Adapters/ApiAdapter";
import {setData} from "../../Reducers/Blog/Article";
import NewsRepository from "../../Repositories/NewsRepository";
import ControlPanelBase from "../../Components/ControlPanel/ControlPanelBase";

import no_image from "../../img/no_image.png"

const Content = ({image, text, date}) => {

    const dateTime = new Date(date)

    return (
        <div className="card border-0 shadow-none my-0">
            <div className="card-body border-0">
                <div className="card ">
                    <div className="position-relative">
                        {image ?
                            <img className="card-img-top d-block" src={`data:image/jpeg;base64,${image}`}
                                 alt="Card img cap"/> :
                            <img className="card-img-top d-block" src={no_image}
                                 alt="Card img cap"/>
                        }
                    </div>
                    <div className="card-body">
                        {/*<h5>Bacon chicken turducken spare ribs.</h5>*/}
                        <p className="card-text fs-5">{text}</p></div>
                </div>
            </div>
            <div className="justify-content-between card-footer mx-3">
                <div>
                    <p className="h6 text-uppercase mb-0 ms-auto text-primary">
                        <time dateTime="2019-05-31">{dateTime.toLocaleDateString(
                            'uk-UA',
                            {
                                day: "numeric",
                                month: 'long',
                                year: 'numeric'
                            }
                        )}</time>
                    </p>
                </div>
                <div>
                <p className="h6 text-uppercase mb-0 ms-auto text-primary">
                        Прес Служба
                    </p>
                </div>
            </div>
        </div>
    )
}


const Body = () => {

    const data = useSelector(state => state.Article.data)

    return (
        <>
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">{data.title}</h1>
            </div>
            <div className="contact-body">
                <div className="contact-list-view mt-3">
                    <div className="mb-3 row">
                        <div className="col-auto">
                            <ControlPanelBase/>
                        </div>
                    </div>
                    <div className="mt-3 row">
                        <div className="col">
                            <Content image={data.image} text={data.text} date={data.date}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const getData = async (slug, dispatch) => {
    const apiAdapter = ApiAdapter();
    const {getArticle} = NewsRepository(apiAdapter);
    await getArticle(slug, dispatch, setData)
}

const Article = () => {

    const dispatch = useDispatch()
    const {slug} = useParams();
    useEffect(() => {
        getData(slug, dispatch).then();
    }, []);

    return (
        <div className="container-xxl">
            <Body/>
        </div>
    )
}

export default Article
