import {createSlice} from "@reduxjs/toolkit";

import {passwordResetConfirm} from "./Auth2";

const initialState = {
    url: undefined
}

export const Redirect = createSlice({
    name: 'Redirect',
    initialState,
    reducers: {
        reset: () => initialState,
        setRedirectUrl: (state, action) => {
            return {...state, url: action.payload}
        }
    },
    extraReducers: (builder) => {
        builder
            //--- AUTH ERRORS
            .addCase(passwordResetConfirm.rejected, (state, action) => {
                return {...state, url: "/"}
            })
    }
})

export const {
    reset,
    setRedirectUrl} = Redirect.actions
export default Redirect.reducer