import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    // timeStamp: Date.now().toString(),
    data: {
        title: "",
        text: "",
        image: "",
        imageBase64: "",
        date: "",
        author: "",
        permalink: "",
        status: ""
    },
    loading: true,
}

export const Article = createSlice({
    name: 'Article',
    initialState,
    reducers: {
        reset: () => initialState,
        setData: (state, action) => {
            return {...state, data: action.payload}
        },
        setTimeStamp: (state, action) => {
            return {...state, timeStamp: Date.now().toString()}
        },
    },
})

export const {
    reset,
    setData,
    setTimeStamp
} = Article.actions
export default Article.reducer