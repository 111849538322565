import {useContext, useEffect, useMemo} from "react";
import AuthContext from "../../../Components/AuthContext";
import Pagination from "../../../Components/Pagination/Pagination";
import {filterArrayOfObjects} from "../../../Utils/arrayProcessing";
import {reset as resetFilteredData, setFilteredData} from "../../../Reducers/FilteredData";
import {useDispatch, useSelector} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faFileCirclePlus,
    faEllipsisVertical,
    faGear,
    faImages,
    faTrashCan,
    faPencil, faArrowLeft, faHouse
} from "@fortawesome/free-solid-svg-icons";
import {
    faImage,
} from "@fortawesome/free-regular-svg-icons";

import {setData, setTimeStamp} from "../../../Reducers/Blog/Blog";
import {setErrors} from "../../../Reducers/Errors";

import {useTable} from "react-table";
import Dropdown from "react-bootstrap/Dropdown";
import {Badge, ButtonGroup} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {handleError, reportError} from "../../../Utils/Errors";
import ControlPanelButton from "../../../Components/ControlPanel/ControlPanelButton";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
const locale = "uk_UA"

const PostsListTableRow = ({rowData}) => {

    const navigate = useNavigate()

    return (
        <tr {...rowData.getRowProps()}>
            <td className="">
                <span className="">
                    {
                        rowData.original.image ?
                            <FontAwesomeIcon icon={faImage} className="text-primary"/> :
                            <FontAwesomeIcon icon={faImage} style={{"color": "#e2e2e2"}}/>
                    }
                </span>
            </td>
            <td className="mw-250p text-truncate text-high-em sorting_1">
                <span>{rowData.original.title}</span>
            </td>
            {/*<td className=""><span className="d-block">{'Morgan Freeman'}</span></td>*/}
            <td className=""><span>
                {(() => {
                    switch (rowData.original.status) {
                        case "published":
                            return <Badge pill bg="primary">Опубліковано</Badge>
                        default:
                            return <Badge pill bg="light" className="text-secondary">Чорновик</Badge>
                    }
                })()}
            </span></td>
            <td className=""><span>{rowData.original.date}</span></td>
            <td className=""><span>{rowData.original.date_modify}</span></td>

            <td className="">
                <div className="d-flex align-items-center">
                    <div className="dropdown">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle
                                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover dropdown-toggle no-caret"
                                id="rowActions">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faEllipsisVertical} className="text-secondary"/>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-210p">
                                <Dropdown.Item eventKey="1" onClick={() => {
                                    navigate(rowData.original.permalink)
                                }}>
                                    <FontAwesomeIcon icon={faPencil} className="text-warning me-2"/>
                                    Редагувати</Dropdown.Item>
                                {/*<Dropdown.Divider/>*/}
                                {/*<Dropdown.Item eventKey="2">Опублікувати</Dropdown.Item>*/}
                                {/*<Dropdown.Item eventKey="3">Чернетка</Dropdown.Item>*/}
                                {/*<Dropdown.Divider/>*/}
                                {/*<Dropdown.Item className="" eventKey="4">*/}
                                {/*    <FontAwesomeIcon icon={faTrashCan} className="text-warning me-2"/>*/}
                                {/*    Видалити*/}
                                {/*</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </td>
        </tr>
    )
}

const PostsListTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)
    // const data = ['', '', '', '', '', '', '']

    const columns = useMemo(
        () => [
            {Header: 'Title', accessor: 'title'},
            {Header: 'Permalink', accessor: 'permalink'},
            {Header: 'Status', accessor: 'status'},
            {Header: 'Date', accessor: 'date'}
        ], [])

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});

    return (
        <table className="hk-data-table nowrap w-100 mb-5 table" {...getTableProps()}>
            <thead>
            <tr>
                <th className="">
                    <span className="flex-grow-1"><FontAwesomeIcon icon={faImages} className="text-primary"/></span>
                </th>
                <th className=""><span className="flex-grow-1">Заголовок</span></th>
                <th className=""><span className="flex-grow-1">Статус</span></th>
                <th className=""><span className="flex-grow-1">Дата</span></th>
                <th className=""><span className="flex-grow-1">Дата оновлення</span></th>
                <th className="">
                    <span className="flex-grow-1">
                        <FontAwesomeIcon icon={faGear} className="text-primary"/>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody>
            {
                rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <PostsListTableRow key={i} rowData={row}/>
                    )
                })
            }
            </tbody>
        </table>
    )
}

const PostsListControlPanel = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div className="d-xxl-flex d-none align-items-center mb-0">

            <ControlPanelButton icon={faArrowLeft} onClick={() => {
                navigate(-1)
            }}/>
            <ControlPanelButton icon={faHouse} onClick={() => {
                        navigate('/')
                    }}/>
            <ControlPanelButton text={"Новий пост"} icon={faFileCirclePlus} onClick={() => {
                        navigate('/admin-news/add')
                    }}/>
            <ControlPanelButton text={"Оновити"} icon={faArrowsRotate} onClick={() => {
                        dispatch(setTimeStamp())
                    }}/>
            {/*<label className="flex-shrink-0 mb-0 me-2">Фільтри:</label>*/}

            {/*<select className="w-130p form-select form-select-sm" defaultValue={"all"}>*/}
            {/*    <option value="all">Всі</option>*/}
            {/*    <option value="active">Опубліковані</option>*/}
            {/*    <option value="connected">Чернетки</option>*/}
            {/*</select>*/}

        </div>
    )
}

const PostsList = () => {

    const dispatch = useDispatch()

    const fData = useSelector(state => state.FilteredData)
    const data = useSelector(state => state.Blog.data)

    function searchHandler(e) {
        const filteredData = filterArrayOfObjects(data, e.target.value)
        dispatch(setFilteredData({data: filteredData, searchValue: e.target.value}))
    }

    return (
        <div className="contact-body">
            <div className="contact-list-view mt-3">
                <div className="mb-3 row">
                    <div className="col-xl-9 col-lg-9">
                        <div className="contact-toolbar-left">
                            <PostsListControlPanel/>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3">
                        <div className="contact-toolbar-right">
                            <div className="dataTables_filter d-flex justify-content-end">
                                <label className="form-label">
                                    <input placeholder="Search" type="search"
                                           className="form-control form-control-sm"
                                           defaultValue=""
                                           onInput={(e) => {
                                               searchHandler(e)
                                           }}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Table*/}
                <div className="table-responsive">
                    <PostsListTable/>
                </div>
                {/*...Table*/}
                {/*Pagination*/}
                <div className="mb-3 row">
                    <div
                        className="d-flex justify-content-end">
                        <Pagination itemsPerPage={10} data={(fData.is_filtered) ? fData.data : data}/>
                    </div>
                </div>
                {/*...Pagination*/}
            </div>
        </div>
    )
}

const AdminNews = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let {isAuthenticated, isAdmin} = useContext(AuthContext)
    const timeStamp = useSelector(state => state.Blog.timeStamp)

    useEffect(() => {

        dispatch(resetFilteredData())

        if (isAuthenticated !== true || isAdmin !== true) {
            navigate("/")
        } else {
            axios.get(`${baseUrl}/news/admin/news-list/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {

                if (response.status === 200) {

                    const responseData = response.data;
                    switch (responseData.status) {
                        case "SUCCESS":
                            dispatch(setData(responseData.data))
                            break;
                        default:
                            dispatch(setErrors([reportError('OperationErrorTryLater', locale)]))
                    }
                }

            }).catch(function (error) {
                const errorList = handleError(error)
                dispatch(setErrors(errorList))
            })
        }
    }, [isAuthenticated, timeStamp]);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Новини</h1>
            </div>
            <div className="hk-pg-body">
                <div className="contactapp-content">
                    <div className="contactapp-detail-wrap">
                        {/*User list*/}
                        <PostsList/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminNews