import Nav from "react-bootstrap/Nav";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import ErrorNotification from "../../Components/Notifications/ErrorNotification";
import {ListGroup} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import ErrorMessage from "../../Components/Errors/ErrorMessage";
import {getProfileData, updateProfileData} from "./repository"

import profileBG from "../../img/profile_bg.jpg"

const TabContentProfileData = ({user_info}) => {


    const dispatch = useDispatch()

    const methods = useForm()
    const {
        setValue,
        register,
        setError,
        handleSubmit,
        formState: {errors}
    } = methods

    const onSubmitHandler = (formValues) => {
        if (!(formValues.phone_number.match('^380[0-9]{9}'))) {
            setError("phone_number", {type: "notValid", message: "invalid"}, {shouldFocus: true})
        } else {
            updateProfileData(formValues, dispatch)
        }
    }
    useEffect(() => {
        Object.keys(user_info).forEach((el) => {
            setValue(el, user_info[el])
            register(el, {required: true})
        })
    }, [user_info, methods]);

    return (
        <div className="tab-pane" id="tab_profile_data">
            <FormProvider {...methods}>
                <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>
                    {/*<div className="row gx-3">*/}

                    {/*    <div className="col-sm-12">*/}
                    {/*        <div className="profile-wrap">*/}
                    {/*            <div className="profile-img-wrap">*/}
                    {/*                <img className="img-fluid rounded-5"*/}
                    {/*                     src={profileBG}*/}
                    {/*                     alt="Img Description"/>*/}
                    {/*            </div>*/}
                    {/*            <div className="profile-intro">*/}
                    {/*                <div className="card-flush mw-400p bg-transparent card">*/}
                    {/*                    <div className="card-body">*/}
                    {/*                        <div*/}
                    {/*                            className="avatar avatar-primary avatar-xxl avatar-rounded">*/}
                    {/*                            <span className="initial-wrap">{user_info.short_name}</span>*/}
                    {/*                        </div>*/}
                    {/*                        <h4>{`${user_info?.first_name} ${user_info?.last_name}`}</h4>*/}
                    {/*                        <p>{user_info?.username}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                        <span>Особиста інформація</span>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Ім&apos;я</label>
                                <input className={"form-control"}
                                    // placeholder="user@example.com"
                                       autoComplete={"on"}
                                       id="first_name"
                                       {...register("first_name", {required: true})}
                                       type="text"/>
                                <ErrorMessage field={"Ім&apos;я"} type={errors["first_name"]?.type}/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Прізвище</label>
                                <input className={"form-control"}
                                    // placeholder="user@example.com"
                                       autoComplete={"on"}
                                       id="last_name"
                                       {...register("last_name", {required: true})}
                                       type="text"/>
                                <ErrorMessage field={"Прізвище"} type={errors["last_name"]?.type}/>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="form-label">Email адреса</label>
                                <input className={"form-control"}
                                       placeholder="user@example.com"
                                       autoComplete={"on"}
                                       id="email"
                                       {...register("email", {required: true})}
                                       type="text"/>
                                <ErrorMessage field={"Email адреса"} type={errors["email"]?.type}/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                        <span>Додаткова інформація</span></div>
                    <div className="row gx-3">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="form-label">Назва компанії</label>
                                <input className={"form-control"}
                                    // placeholder="user@example.com"
                                       autoComplete={"on"}
                                       readOnly={true}
                                       id="company_name"
                                       {...register("company_name", {required: true})}
                                       type="text"/>
                                <ErrorMessage field={"Назва компанії"} type={errors["company_name"]?.type}/>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="form-label">Телефон</label>
                                <input className={"form-control"}
                                       placeholder="380971234567"
                                       autoComplete={"on"}
                                       id="phone_number"
                                       {...register("phone_number", {required: true})}
                                       type="text"/>
                                <ErrorMessage field={"Телефон"} type={errors["phone_number"]?.type}/>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary mt-5" name="submit">Зберегти</button>
                </form>
            </FormProvider>
        </div>
    )
}

const TabContentAdditionalParameters = () => {
    return (
        <div className="tab-pane" id="tab_additional_parameters">
            <div className="title-lg fs-4 mb-4"><span>Додаткові параметри</span></div>
            <form>
                <div className="row gx-3">
                    {/*<div className="col-sm-12">*/}
                    {/*    <div className="form-check form-check-lg">*/}
                    {/*        <input type="checkbox" className="form-check-input"*/}
                    {/*               id="customChecks1"/>*/}
                    {/*        <label className="form-check-label mt-0"*/}
                    {/*               htmlFor="customChecks1">let others find me by email*/}
                    {/*            address</label>*/}
                    {/*        <small className="form-text text-muted d-block">People who*/}
                    {/*            have your email address will be able to connect you by*/}
                    {/*            Jampack</small>*/}
                    {/*    </div>*/}
                    {/*    <div className="separator"></div>*/}
                    {/*    <div className="form-check form-check-lg">*/}
                    {/*        <input type="checkbox" className="form-check-input"*/}
                    {/*               id="customChecks2"/>*/}
                    {/*        <label className="form-check-label mt-0"*/}
                    {/*               htmlFor="customChecks2">Keep my phone number*/}
                    {/*            private</label>*/}
                    {/*        <small className="form-text text-muted d-block">No one can*/}
                    {/*            find you by your phone number. Your phone number will*/}
                    {/*            not be shared with your contact anymore.</small>*/}
                    {/*    </div>*/}
                    {/*    <div className="separator"></div>*/}
                    {/*    <div className="form-check form-check-lg">*/}
                    {/*        <input type="checkbox" className="form-check-input"*/}
                    {/*               id="customChecks3"/>*/}
                    {/*        <label className="form-check-label mt-0"*/}
                    {/*               htmlFor="customChecks3">All Keep my location sharing*/}
                    {/*            on</label>*/}
                    {/*        <small className="form-text text-muted d-block">Jmapack*/}
                    {/*            webapp shares your location wherever you go</small>*/}
                    {/*    </div>*/}
                    {/*    <div className="separator"></div>*/}
                    {/*    <div className="form-check form-check-lg">*/}
                    {/*        <input type="checkbox" className="form-check-input"*/}
                    {/*               id="customChecks4"/>*/}
                    {/*        <label className="form-check-label mt-0"*/}
                    {/*               htmlFor="customChecks4">Share data through select*/}
                    {/*            partnerships</label>*/}
                    {/*        <small className="form-text text-muted d-block">Lorem ipsum*/}
                    {/*            dolor sit amet, consectetur adipiscing elit.*/}
                    {/*            Pellentesque condimentum mauris volutpat enim ornare*/}
                    {/*            iaculis. Curabitur euismod rutrum lorem id lobortis.*/}
                    {/*            Cras ut ex dui. Nulla sed blandit tortor. In quam diam,*/}
                    {/*            efficitur sit amet pulvinar eget, consequat placerat*/}
                    {/*            arcu.</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*<button type="button" className="btn btn-primary mt-5">Зберегти зміни*/}
                {/*</button>*/}
            </form>
        </div>
    )
}

const TabContentLoginSecurity = () => {
    return (
        <div className="tab-pane" id="tab_login_security">
            <div className="title-lg fs-4"><span>Вхід і безпека</span></div>
            <p className="mb-4">Тут ви можете змінити свій пароль.</p>

            <div
                className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                <span>Параметри пароля</span></div>
            <div className="row gx-3 mb-3">
                <ListGroup as="ol" variant="flush" className="border-0">
                    <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                        - Пароль не може бути надто схожим на іншу особисту інформацію.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                        - Пароль не може бути одним із дуже поширених.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                        - Ваш пароль повинен містити як мінімум 8 символів. Букви, цифри, символи.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                        - Ваш пароль повинен містити як мінімум одну велику і одну маленьку букви.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                        - Ваш пароль повинен містити як мінімум один iз символів: # @ $ & %.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                        - Пароль не може складається лише із цифр.
                    </ListGroup.Item>
                </ListGroup>
            </div>
            <div className="row gx-3">
                <div className="col-sm-12">
                    <div className="form-group">
                        <button type="button"
                                className="btn btn-primary mt-3">Змінити пароль
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UserProfileHeader = ({user_info}) => {

    return (
        <div className="row gx-3">

            <div className="col-sm-12">
                <div className="profile-wrap">
                    <div className="profile-img-wrap">
                        <img className="img-fluid rounded-5"
                             src={profileBG}
                             alt="Img Description"/>
                    </div>
                    <div className="profile-intro">
                        <div className="card-flush mw-400p bg-transparent card">
                            <div className="card-body">
                                <div
                                    className="avatar avatar-primary avatar-xxl avatar-rounded">
                                    <span className="initial-wrap">{user_info.short_name}</span>
                                </div>
                                <h4>{`${user_info?.first_name} ${user_info?.last_name}`}</h4>
                                <p>{user_info?.username}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavContentProfile = () => {

    const user_info = useSelector(state => state.User.data.profile)

    return (
        <>
            <UserProfileHeader user_info={user_info}/>
            <Tab.Container id="left-tabs" defaultActiveKey="tab_profile_data">
                <Row className="edit-profile-wrap">
                    <Col className="col-lg-2 col-sm-3 col-4">
                        <div className="nav-profile mt-4">
                            <div className="nav-header">
                                <span className="fs-7 text-primary">Обліковий запис</span>
                            </div>
                            <Nav className="nav-light nav-vertical nav-tabs" as="ul">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="tab_profile_data">
                                        <span className="nav-link-text">Мій профіль</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_additional_parameters">
                                        <span className="nav-link-text">Додаткові параметри</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_login_security">
                                        <span className="nav-link-text">Вхід і безпека</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Col>
                    <Col className="col-lg-10 col-sm-9 col-8">
                        <Tab.Content>
                            <Tab.Pane eventKey="tab_profile_data"><TabContentProfileData
                                user_info={user_info}/></Tab.Pane>
                            <Tab.Pane eventKey="tab_additional_parameters"><TabContentAdditionalParameters/></Tab.Pane>
                            <Tab.Pane eventKey="tab_login_security"><TabContentLoginSecurity/></Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}


const Profile = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        getProfileData(dispatch).then();
    }, [dispatch]);

    return (
        <div className="container-xxl">

            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Редагувати профіль</h1>
            </div>

            <div className="hk-pg-body">
                <NavContentProfile/>
            </div>
            {/*<ErrorsSection store={'User'}/>*/}
            <ErrorNotification position={'bottom-left'}/>
        </div>
    )
}

export default Profile