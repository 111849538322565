import MainNavBar from "./MainNavBar/MainNavBar";
import Footer from "./Footer/Footer";


const PageContext = ({children}) => {

    return(
        <div className="hk-wrapper" data-layout="navbar" data-layout-style="default" data-menu="light"
             data-footer="simple">
                <MainNavBar/>
                <div className="hk-pg-wrapper">
                    {children}
                    <Footer/>
                </div>
        </div>
    )
}

export default PageContext