import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faHryvnia
} from "@fortawesome/free-solid-svg-icons";

import {Table} from "react-bootstrap";

import {reset as resetOrdersState, setData, setLoading} from "../../../Reducers/Orders";

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {emptyOrValue, emptyOrZero} from "../../../Utils/utils.js"

import Pagination from "../../../Components/Pagination/Pagination";
import ApiAdapter from "../../../Adapters/ApiAdapter";
import DataRepository from "../../../Repositories/DataRepository";
import {reset as resetPagination} from "../../../Reducers/Pagination";
import BorderedSpinner from "../../../Components/Spinner/Spinner";
import {filterArrayOfObjects} from "../../../Utils/arrayProcessing";
import {setFilteredData} from "../../../Reducers/FilteredData";


const FormCheck = () => {

    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch()

    function handleCheck() {
        setChecked(!checked)
        dispatch(resetOrdersState())
        getData(!checked, dispatch).then()
    }

    return (
        <div className="ms-auto form-check form-switch mx-3">
            <input type="checkbox" id="custom-switch"
                   className="form-check-input form-check-sm"
                   checked={checked}
                   onChange={handleCheck}/>
            <label title="" htmlFor="switch-xl" className="form-check-label">
                Тільки по відвантаженим замовленням</label>
        </div>
    )
}

const ReportTableRow = ({rowData}) => {
    return (
        <tr className="odd">
            <td className="sorting_1">
                <div className="media align-items-center">
                    <div className="media-body">
                        <div className="text-high-em">
                            <a href={`/orders/order-details/${rowData.uid}`}
                               className="table-link-text link-high-em">{rowData.number}</a>
                        </div>
                    </div>
                </div>
                <div className="fs-7">{rowData.date}</div>
            </td>
            <td>{rowData.delivery_address}</td>
            <td>{rowData.delivery_date}</td>

            <td>
                <div className="text-dark">{rowData.manager === undefined ? "" : rowData.manager.last_name}</div>
                <div className="text-dark">{rowData.manager === undefined ? "" : rowData.manager.first_name}</div>
            </td>

            <td>{emptyOrValue(rowData.order_amount)}</td>
            <td className="text-dark">{emptyOrValue(rowData.debt_amount)}</td>
        </tr>
    )
}

const ReportTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    return (
        <Table responsive>
            <thead>
            <tr role="row">
                <th className="sorting_disabled mnw-150p" tabIndex="0" rowSpan="1" colSpan="1">
                    Замовлення #
                </th>
                <th className="sorting_disabled w-25 mnw-425p" tabIndex="0" rowSpan="1" colSpan="1">
                    Адреса доставки
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Дата доставки
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Менеджер
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Сума
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Борг
                </th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(el => <ReportTableRow key={el.row_id} rowData={el}/>)
            }
            </tbody>
        </Table>
    )
}

const ReportBody = () => {

    const dispatch = useDispatch()

    const fData = useSelector(state => state.FilteredData)
    const rData = useSelector(state => state.Orders.data);
    const loading = useSelector(state => state.Orders.loading)

    function searchHandler(e) {
        const filteredData = filterArrayOfObjects(rData, e.target.value)
        dispatch(setFilteredData({data: filteredData, searchValue: e.target.value}))
    }

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-body">
                <div className="contact-list-view">
                    <div id="datable_1_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}
                        <div className="row">
                            <div className="col-10 mb-3">
                                <div className="taskbar-toolbar">

                                    <FormCheck/>
                                </div>
                            </div>
                            <div className="col-2 mb-3">
                                <div className="contact-toolbar-right">
                                    <div id="datable_1_filter" className="dataTables_filter">
                                        <label>
                                            <input placeholder="Пошук" type="search"
                                                   className="form-control form-control-sm"
                                                   defaultValue=""
                                                   onInput={(e) => {
                                                       searchHandler(e)
                                                   }}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Head*/}
                        {/*Table*/}
                        <div className="row">
                            <div className="col-sm-12 table-responsive">
                            {(() => {
                                        if (loading === false) {
                                            return <ReportTable/>
                                        } else
                                            return <div className="my-3 d-flex justify-content-center">
                                                <BorderedSpinner/>
                                            </div>
                                    }
                                )()}
                                {/*<ReportTable/>*/}
                            </div>
                        </div>
                        {/*Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <Pagination itemsPerPage={10} data={(fData.is_filtered) ? fData.data : rData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <div className="d-flex justify-content-start">
                            <h4>Сума заборгованості: <span
                                className=" ms-3 text-yellow">
                                <FontAwesomeIcon icon={faHryvnia} className="bi me-2"/>
                                {emptyOrZero(rData.reduce((n, {debt_amount}) => n + debt_amount, 0))}
                            </span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="d-flex justify-content-start">
                            <h4>Кількість замовлень: <span
                                className=" ms-3 text-yellow">
                                {emptyOrZero(rData.length,0)}
                            </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const getData = async (only_shipped, dispatch) => {
    const apiAdapter = ApiAdapter();
    const {getDebtAnalysis} = DataRepository(apiAdapter);
    await getDebtAnalysis(only_shipped, dispatch, setData)
    dispatch(setLoading(false))
}

const DebtAnalysis = () => {

    const dispatch = useDispatch()
    // const dateRange = useSelector(state => state.DateRangePicker);

    useEffect(() => {
        dispatch(resetPagination())
        dispatch(resetOrdersState())
        getData(false, dispatch).then()
    }, []);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Аналіз заборгованості</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <ReportBody/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DebtAnalysis