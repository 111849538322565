import {Navigate} from 'react-router-dom';

import AuthContext from "./AuthContext";
import {useContext} from "react";

export {PrivateRoute, AdminRoute};

function PrivateRoute({children}) {

    const {isAuthenticated} = useContext(AuthContext)

    if (isAuthenticated !== true) {
        return <Navigate replace to="/login"/>
    }
    else {

        return children
    }
}

function AdminRoute({children}) {

    let {isAuthenticated, isAdmin} = useContext(AuthContext)

    if (isAuthenticated !== true) {
        // not logged in so redirect to login page with the return url
        return <Navigate replace to="/login"/>
    }

    if (isAuthenticated === true && isAdmin === true) {
        return children
    }

}