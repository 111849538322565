// import './css/style.css'
import './scss/style.scss'

import {Routes, Route, Navigate} from "react-router-dom";
import {AuthProvider} from "./Components/AuthContext";

import {PrivateRoute, AdminRoute} from "./Components/PrivateRoute";

import Login from "./Pages/Authentication/Login/Login";
import Signup from "./Pages/Authentication/Signup/Signup";
import Main from "./Pages/Main/Main";
import Profile from "./Pages/Users/Profile";
import Shipments from "./Pages/Orders/Shipments";
import PageContext from "./Components/PageContext";
import InWork from "./Pages/Orders/InWork";
import OrderPreview from "./Pages/Orders/OrderPage/OrderPreview";
import Archive from "./Pages/Orders/Archive";
import DebtAnalysis from "./Pages/Reports/DebtAnalysis/DebtAnalysis";

import Notification from "./Components/Notifications/Notification";
import ErrorNotification from "./Components/Notifications/ErrorNotification";
import News from "./Pages/Blog/News";

import AdminNews from "./Pages/Admin/News/News";
import AddPost from "./Pages/Admin/News/AddPost"

import AdminUsers from "./Pages/Admin/AdminUsers";
import Article from "./Pages/Blog/Article";
import Support from "./Pages/Support/Support";
import Page404 from "./Pages/404";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import PasswordResetConfirm from "./Pages/Authentication/PasswordResetConfirm";

// import PkiValidation from "./files/73309B502ED9815E400B35BAA43FE7FA.txt"

function App() {
    return (
        <div>
            <ErrorNotification/>
            <Notification/>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<PrivateRoute>
                        <PageContext> <Main/> </PageContext>
                    </PrivateRoute>}/>
                    {/*<PrivateRoute path="Main/" children={<Main/>}></PrivateRoute>*/}

                    <Route path="/orders/in-work" element={<PrivateRoute>
                        <PageContext> <InWork/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/shipments" element={<PrivateRoute>
                        <PageContext> <Shipments/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/archive" element={<PrivateRoute>
                        <PageContext> <Archive/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/order-details/:uid"
                           element={<PrivateRoute> <PageContext> <OrderPreview/> </PageContext>
                           </PrivateRoute>}/>

                    <Route path="/reports/debt-analysis" element={<PrivateRoute>
                        <PageContext> <DebtAnalysis/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/news" element={<PrivateRoute>
                        <PageContext> <News/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/support" element={<PrivateRoute>
                        <PageContext> <Support/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/news/article/:slug" element={<PrivateRoute>
                        <PageContext> <Article/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/users/profile" element={<PrivateRoute>
                        <PageContext> <Profile/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/users/profile" element={<PrivateRoute>
                        <PageContext> <Profile/> </PageContext>
                    </PrivateRoute>}/>

                    Error elements
                    <Route path="/404" element={<PrivateRoute>
                        <PageContext> <Page404/> </PageContext>
                    </PrivateRoute>}/>
                    ...Error elements

                    {/*Admin elements*/}
                    <Route path="/admin-news" element={<AdminRoute>
                        <PageContext> <AdminNews/> </PageContext>
                    </AdminRoute>}/>
                    <Route path="/admin-news/add" element={<AdminRoute>
                        <PageContext> <AddPost/> </PageContext>
                    </AdminRoute>}/>
                    <Route path="/admin-news/:slug" element={<AdminRoute>
                        <PageContext> <AddPost/> </PageContext>
                    </AdminRoute>}/>

                    <Route path="/admin-users" element={<AdminRoute>
                        <PageContext> <AdminUsers/> </PageContext>
                    </AdminRoute>}/>
                    {/*...Admin elements*/}

                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>

                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    {/*<Route path="/verify-registration/:uid" element={<_VerifyRegistration/>}/>*/}
                    <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm/>}
                           errorElement={<Page404/>}/>

                    {/*<Route path="/.well-known/pki-validation/73309B502ED9815E400B35BAA43FE7FA.txt"*/}
                    {/*       element={<PkiValidation/>}/>*/}

                    <Route path="*" element={<Navigate to="/404"/>}/>

                    {/*<redirect from="/" to="/login" />*/}
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
