import {useFormContext} from "react-hook-form";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye,
    faEyeSlash
} from "@fortawesome/free-regular-svg-icons";
import ErrorMessage from "../../Components/Errors/ErrorMessage";

const PassInput = ({name, label, forgotButton= false, forgotLink="#", readOnly=false}) => {

    const {register, formState: {errors}} = useFormContext();

    const [showPassword, setShowPassword] = useState(false)

    function onClickHandler() {
        setShowPassword(!showPassword);
    }

    return (
        <div className="form-group col-lg-12">
            <div className="form-label-group">
                <label htmlFor={name}>{label}</label>
                {
                    forgotButton ? <a href={forgotLink} className="fs-7 fw-medium">Забули пароль ?</a> : <></>
                }
            </div>
            <div className="input-group password-check">
                <span className="input-affix-wrapper">
                        <input className={"form-control"}
                               autoComplete={"on"}
                               placeholder="Введіть ваш пароль"
                               id={name}
                               readOnly={readOnly}
                               {...register(name, {required: true})}
                               type={showPassword ? "test" : "password"}
                               required={true}/>
                        <a href="#" className="input-suffix text-muted mt-1">
                            <span className="feather-icon"
                                  onClick={onClickHandler}>
                                {
                                    showPassword ? <FontAwesomeIcon icon={faEye} className="fs-0"/> :
                                        <FontAwesomeIcon icon={faEyeSlash} className="fs-0"/>
                                }

                            </span>
                        </a>
                </span>
            </div>
            <ErrorMessage field={label} type={errors[name]?.type}/>
        </div>
    )
}

export default PassInput