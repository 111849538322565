import {Button, Modal} from "react-bootstrap";

import {
    faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
    faBan,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";
import {setErrors} from "../../Reducers/Errors";
import {handleError, reportError} from "../../Utils/Errors";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import ErrorMessage from "../Errors/ErrorMessage";
import {updateUserUid} from "../../Reducers/Users";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
const locale = "uk_UA"

const UpdateUserUID = ({show, showHandler, userName, companyName}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const methods = useForm()
    const {register, handleSubmit, formState: {errors}} = methods

    let {isAuthenticated, isAdmin} = useContext(AuthContext)
    const [userUid, setUserUid] = useState('')

    const onSubmitHandler = (formValues) => {
        // console.log(formValues)
        dispatch(updateUserUid(formValues))
        showHandler(false);
    }

    useEffect(() => {

        if (isAuthenticated !== true || isAdmin !== true) {
            navigate("/")
        } else {

            if (show === true) {

                axios.get(`${baseUrl}/users/user-uid/${userName}/`,
                    {
                        timeout: 5000,
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            "X-CSRFToken": Cookies.get("csrftoken")
                        }
                    }).then(function (response) {

                    if (response.status === 200) {

                        const responseData = response.data;
                        switch (responseData.status) {
                            case "SUCCESS":
                                setUserUid(responseData.data.profile.uid)
                                break;
                            default:
                                dispatch(setErrors([reportError('OperationErrorTryLater', locale)]))
                        }
                    }

                }).catch(function (error) {
                    const errorList = handleError(error)
                    dispatch(setErrors(errorList))
                })
            }
        }
    }, [isAuthenticated, show]);

    return (
        <Modal size="lg" centered show={show} onHide={() => showHandler(false)}>

            <FormProvider {...methods}>
                <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>

                    <div className="modal-content border-0">
                        <Modal.Header closeButton className="mx-2">
                            <Modal.Title>Оновити ідентифікатор</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="contact-info w-xxl-100">
                                <div className="card">
                                    <div className="card-header"><a href="#">Інформація профілю</a>

                                    </div>
                                    <div className="card-body">
                                        <div className="gx-3 row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Логін</label>
                                                    <input type="text" className="form-control" readOnly={true}
                                                           id="user_name"
                                                           name="user_name"
                                                           defaultValue={userName}
                                                           {...register("user_name", {required: true})}/>
                                                    <ErrorMessage field={"Логін"} type={errors["user_name"]?.type}/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Назва компанії</label>
                                                    <input type="text" className="form-control" readOnly={true}
                                                           defaultValue={companyName}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gx-3 row">
                                            <div className="col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Ідентифікатор</label>
                                                    <input type="text" className="form-control"
                                                           id="uid"
                                                           name="uid"
                                                           placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                                           defaultValue={userUid}
                                                           {...register("uid", {required: true})}/>
                                                    <ErrorMessage field={"Ідентифікатор"}
                                                                  type={errors["uid"]?.type}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn py-2 btn-sm" type="submit">
                                <FontAwesomeIcon icon={faShareFromSquare}
                                                 className="fs-0 me-2 text-yellow"/>
                                Оновити
                            </Button>
                            <Button className="btn bg-warning py-2 btn-sm border-yellow"
                                    onClick={() => {
                                        showHandler(false)
                                    }}>
                                <FontAwesomeIcon icon={faBan} className="fs-0 me-2 text-primary"/>
                                Скасувати
                            </Button>
                        </Modal.Footer>
                    </div>

                </form>
            </FormProvider>

        </Modal>
    )
}

export default UpdateUserUID