import {createSlice} from "@reduxjs/toolkit";

import {login, signup, getVerificationCode, passwordReset} from "./Auth2";

const initialState = {
    data: []
}

export const Errors = createSlice({
    name: 'Errors',
    initialState,
    reducers: {
        reset: () => initialState,
        setErrors: (state, action) => {
            return {...state, data: action.payload}
        }
    },
    extraReducers: (builder) => {
        builder
            //--- AUTH ERRORS
            .addCase(login.rejected, (state, action) => {
                return {...state, data: action.payload}
            })
            .addCase(signup.rejected, (state, action) => {
                return {...state, data: action.payload}
            })
            .addCase(getVerificationCode.rejected, (state, action) => {
                return {...state, data: action.payload}
            })
            .addCase(passwordReset.rejected, (state, action) => {
                return {...state, data: action.payload}
            })
    }
})

export const {
    reset,
    setErrors
} = Errors.actions
export default Errors.reducer