import {setErrors} from "../Reducers/Errors";
import {setNotifications} from "../Reducers/Notifications";
import {setRedirectUrl} from "../Reducers/Redirect";
import {handleError} from "../Utils/Errors";

function setResponseAlerts(errors, notifications, dispatch) {

    if (errors)
        dispatch(setErrors(handleError(errors)));

    if (notifications)
        dispatch(setNotifications(notifications));
}

function repositoryResponse(status, responseData, dispatch, setData) {

    switch (status) {
        case 200: {
            switch (responseData.status) {
                case "SUCCESS":
                    if (dispatch) {
                        if (responseData?.data)
                            dispatch(setData(responseData.data));
                        setResponseAlerts(responseData.errors, responseData?.notifications, dispatch)
                    }
                    if (responseData?.redirectUrl) {
                        dispatch(setRedirectUrl(responseData.redirectUrl))
                    }
                    break;
                default:
                    dispatch(setErrors(['undefined error']))
            }
            break;
        }

        default: {
            window.location.href = '/';
        }
    }
}

function errorResponse(status, dispatch, error, setRedirect = undefined) {
    switch (status) {
        case 404: {
            window.location.href = '/404';
            break
        }
        case 301: {
            setRedirect(true)
            break
        }
        default: {
            const errorList = handleError(error)
            dispatch(setErrors(errorList))
        }
    }
}

export {repositoryResponse, errorResponse}