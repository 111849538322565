import ApiAdapter from "../../Adapters/ApiAdapter";
import {AuthRepository} from "../../Repositories/AuthRepository";
import {setData} from "../../Reducers/UserProfile";

export const getProfileData = async (dispatch) => {

    const apiAdapter = ApiAdapter();
    const {getProfileData} = AuthRepository(apiAdapter);
    await getProfileData(dispatch, setData)

}

export const updateProfileData = async (data, dispatch) => {

    const apiAdapter = ApiAdapter();
    const {updateProfileData} = AuthRepository(apiAdapter);
    await updateProfileData(data, dispatch)

}

