import {Button, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareFromSquare} from "@fortawesome/free-regular-svg-icons";
import {faBan, faCalendarDay} from "@fortawesome/free-solid-svg-icons";

import "react-datepicker/dist/react-datepicker.css";
import {SelectDateForm} from "../../../Components/DateRangePicker/DateRangePicker";
import ErrorMessage from "../../../Components/Errors/ErrorMessage";
import {useDispatch} from "react-redux";
import {sendDeliveryDateUpdateRequest} from "../../../Reducers/OrderData";

const DateWindow = ({show, showHandler, uid, delivery_date}) => {

    // const [startDate, setStartDate] = useState(new Date());

    // const navigate = useNavigate()
    const dispatch = useDispatch()
    const methods = useForm({
        defaultValues: {
            uid: uid,
            delivery_date: ""
        }
    })
    const {
        clearErrors,
        register,
        handleSubmit,
        formState: {errors}
    } = methods

    // let {isAuthenticated, isAdmin} = useContext(AuthContext)

    const handleDateRangeCallback = (start) => {
        methods.setValue('delivery_date', start.format("YYYYMMDD"))
    };

    const onSubmitHandler =(formValues) => {
        dispatch(sendDeliveryDateUpdateRequest(formValues))
        methods.setValue('delivery_date', "")
        clearErrors('delivery_date')
        showHandler(false);
    }

    return (
        <Modal size="md" centered show={show} onHide={() => showHandler(false)}>
            <FormProvider {...methods}>
                <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="modal-content border-0">
                        <Modal.Header closeButton className="mx-2">
                            <Modal.Title>Вкажіть дату відвантаження</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label className="form-label">Нова дата відвантаження</label>
                            <SelectDateForm id={"delivery_date"}
                                            name={"delivery_date"}
                                            readOnly={false}
                                            startDate={delivery_date}
                                            onCallback={handleDateRangeCallback}
                            {...register("delivery_date", {required: true})}/>
                            <ErrorMessage field={"Дата відвантаження"} type={errors["delivery_date"]?.type}/>

                            <div className="fs-7 mt-3">
                                <FontAwesomeIcon icon={faCalendarDay}
                                                 className="fs-0 me-2 text-primary"/>
                                Дата відвантаження
                            </div>
                            <div className="text-dark fw-medium">{delivery_date}</div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn py-2 btn-sm" type="submit">
                                <FontAwesomeIcon icon={faShareFromSquare}
                                                 className="fs-0 me-2 text-yellow"/>
                                Відправити
                            </Button>
                            <Button className="btn bg-warning py-2 btn-sm border-yellow"
                                    onClick={() => {
                                        showHandler(false)
                                    }}>
                                <FontAwesomeIcon icon={faBan} className="fs-0 me-2 text-primary"/>
                                Скасувати
                            </Button>
                        </Modal.Footer>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default DateWindow