import {handleError} from "../Utils/Errors";
import {errorResponse, repositoryResponse} from "./Response";


const UserRepository = (apiAdapter) => ({

    sendSupportMessage: async (subject, message) => {

        const data = {subject: subject, message: message}

        try {
            const response = await apiAdapter.post('/users/send-support-message/', data)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {errors: handleError(error)}
        }

    },
    deleteUser: async (username, dispatch) =>{
        await apiAdapter.delete(`/users/delete-user/${username}/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, undefined);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },

});

export default UserRepository;