import BrandNav from "../../Pages/Authentication/BrandNav";
import AuthBody from "../../Pages/Authentication/AuthBody";
import Footer from "../../Components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import ErrorMessage from "../../Components/Errors/ErrorMessage";
import {passwordReset} from "../../Reducers/Auth2";
import {faInfo, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ResetPasswordContent = () => {

    const dispatch = useDispatch();
    const passwordResetDone = useSelector(state => state.Auth2.passwordResetDone)

    const methods = useForm()
    const {
        register,
        // setError,
        handleSubmit,
        formState: {errors}
    } = methods

    const onSubmitHandler = (formValues) => {
        dispatch(passwordReset({email: formValues.email}))
    }

    return (
        <div className="auth-content py-8">
            {passwordResetDone === false ?
                <FormProvider {...methods}>
                    <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="row">
                            <div className="mx-auto col-lg-10 col-md-10 col-sm-10">
                                <div className="card-flush card">
                                    <div className="text-center card-body">
                                        <h4>Відновити пароль</h4>
                                        <p className="mb-4">Скидання забутого пароля облікового запису кабінету
                                            електронних
                                            послуг
                                        </p>
                                        <div className="gx-3 row">
                                            <div className="mb-3 col-lg-12">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="userName">Адреса електронної
                                                        пошти</label>
                                                    {/*<a className="fs-7 fw-medium" href="/auth/reset-password">Вхід</a>*/}
                                                </div>
                                                <input placeholder="user@example.com" className="form-control"
                                                       id="email"
                                                       {...register("email", {required: true})}
                                                       type="email"/>
                                                <ErrorMessage field={"Адресу електронної пошти"}
                                                              type={errors["email"]?.type}/>
                                            </div>
                                        </div>
                                        <button type="submit"
                                                className="btn-uppercase btn-block btn btn-primary">Надіслати
                                        </button>
                                        <p className="p-xs mt-2 text-center">
                                            <a className="text-dark-60" href="/signup">Новий користувач?&nbsp;&nbsp;
                                                <u className="text-primary">Створити обліковий запис</u>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
                :
                <div className="row">
                    <div className="mx-auto col-lg-10 col-md-10 col-sm-10">
                        <div className="card-flush card">
                            <div className="text-center card-body">
                                <h4>Відновити пароль</h4>
                                <FontAwesomeIcon icon={faInfoCircle} className="fs-1 mt-3 text-primary"/>
                            </div>
                            <div className="text-start card-body">
                                <p className="mb-4">
                                    {/*<FontAwesomeIcon icon={faInfo} className="fs-0 me-2 text-primary"/>*/}
                                    Ми відправили вам інструкції для встановлення пароля, якщо
                                    обліковий запис з введеною адресою існує. Ви маєте отримати їх найближчим часом.</p>

                                <p className="mb-4">
                                    Якщо Ви не отримали електронного листа, переконайтеся, будь ласка,
                                    в зареєстрованій адресі і перевірте папку "Спам".</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const ResetPassword = () => {
    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>
            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <ResetPasswordContent/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default ResetPassword