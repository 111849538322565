import ApiAdapter from "../../../Adapters/ApiAdapter";
import NewsRepository from "../../../Repositories/NewsRepository";

export const getArticleData = async (slug, dispatch, setData) => {
    const apiAdapter = ApiAdapter();
    const {getAminArticle} = NewsRepository(apiAdapter);
    await getAminArticle(slug, dispatch, setData)
}

export const addNewArticle = async (data, dispatch) => {
    const apiAdapter = ApiAdapter();
    const {addArticle} = NewsRepository(apiAdapter);
    await addArticle(data, dispatch)
}

export const updateArticle = async (slug, data, dispatch) => {

    const apiAdapter = ApiAdapter();
    const {updateArticle} = NewsRepository(apiAdapter);
    await updateArticle(slug, data, dispatch)
}

export const deleteArticle = async (slug, dispatch) => {
    const apiAdapter = ApiAdapter();
    const {deleteArticle} = NewsRepository(apiAdapter);
    await deleteArticle(slug, dispatch)
}