import {createContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {session} from "../Reducers/Auth2";
import {useNavigate} from "react-router-dom";
import {setRedirectUrl} from "../Reducers/Redirect";

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {isAuthenticated, isAdmin} = useSelector(state => state.Auth2)
    const redirectUrl = useSelector(state => state.Redirect.url)

    let contextData = {
        isAuthenticated: isAuthenticated,
        isAdmin: isAdmin
    }

    useEffect(() => {

        if (isAuthenticated)
            dispatch(session())

        if (redirectUrl) {
            navigate(redirectUrl, {replace: true})
            dispatch(setRedirectUrl(undefined))
        }

    }, [navigate, dispatch, redirectUrl, isAuthenticated]);


    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}