import Dropdown from 'react-bootstrap/Dropdown';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUserSecret,
    faArrowRightFromBracket
} from "@fortawesome/free-solid-svg-icons";

import {setLogout} from "../../Reducers/Auth2";

import ApiAdapter from "../../Adapters/ApiAdapter";
import {AuthRepository} from "../../Repositories/AuthRepository";

import {useDispatch} from "react-redux";


const logOut = async (dispatch) => {

    dispatch(setLogout());

    const apiAdapter = ApiAdapter();
    const {logOut} = AuthRepository(apiAdapter);
    await logOut(dispatch)
}

const DropdownProfile = ({userData}) => {

    const dispatch = useDispatch();

    const onLogoutHandler = () => {
        logOut(dispatch).then()
    }

    return (
        <Dropdown className="dropdown ps-2">

            <Dropdown.Toggle
                className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover dropdown-toggle no-caret"
                variant="success" id="dropdown-basic" role="button">
                <div className="media">
                    <div className="media-head">
                        <div className="avatar avatar-icon avatar-xs avatar-primary avatar-rounded">
                            <span className="initial-wrap">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faUserSecret} className="feather fs-6"/>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-end p-0" style={{opacity: "1"}}>
                <Dropdown.Header className="dropdown-header px-4 mt-2 pb-2 fs-6">
                    <div className="p-2">
                        <div className="media">
                            <div className="media-head me-2">
                                <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                    <span className="initial-wrap">{userData.usershort}</span>
                                </div>
                            </div>
                            <div className="media-body">
                                <div className="fs-7">{userData.email}</div>
                                <div className="fs-7">{userData.username}</div>

                                <a href="#" className="d-block fs-8 link-secondary" onClick={onLogoutHandler}>
                                    <u><FontAwesomeIcon icon={faArrowRightFromBracket} className="fs--2 me-2"/>Вийти</u>
                                </a>
                            </div>
                        </div>
                    </div>
                </Dropdown.Header>
                {/*<div className="fs-6 text-primary-vst text-end me-3">{userData.company}</div>*/}
                <div className="dropdown-divider mx-0"></div>
                <Dropdown.Item className="dropdown-item ms-2 mb-2" href="/users/profile">
                    <FontAwesomeIcon icon={faUserSecret} className="fs-0 me-2"/>
                    Мій Профіль
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownProfile