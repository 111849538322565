import {handleError} from "../Utils/Errors";
import {errorResponse, repositoryResponse} from "./Response";

function responseStatus200(state, responseData) {

    switch (responseData.status) {
        case "SUCCESS":
            localStorage.setItem('isAuthenticated', responseData.data.isAuthenticated);

            if (responseData.data?.user_data !== undefined)
                localStorage.setItem('user-data', JSON.stringify(responseData.data.user_data));

            return {
                ...state,
                isAuthenticated: responseData.data.isAuthenticated,
                isAdmin: responseData.data.isAdmin,
                verificationCodeSent: false,
                loading: false
            }
        case "REDIRECT":
            return {
                ...state,
                redirectUrl: responseData.data.redirectUrl,
                notifications: responseData.notifications,
                verificationCodeSent: false,
                loading: false
            }
        default: {
            return {...state, loading: false, errors: handleError(['UndefinedError'])}
        }
    }
}

function verificationFulfilled(state, status, responseData) {

    switch (status) {
        case 200: {
            return {...state, verificationCodeSent: true, loading: false}
        }
        case 400: {
            return {...state, errors: responseData.errors, verificationCodeSent: false, loading: false}
        }
        case 401: {
            return {...state, errors: responseData.errors, verificationCodeSent: false, loading: false}
        }
        default: {
            window.location.href = '/';
        }
    }
}

function signUpInFulfilled(state, status, responseData) {

    switch (status) {
        case 200: {
            return responseStatus200(state, responseData)
        }
        case 400: {
            return {...state, errors: responseData.errors, signUpError: true, loading: false, verificationCodeSent: false}
        }
        case 401: {
            return {...state, errors: responseData.errors, signUpError: true, loading: false, verificationCodeSent: false}
        }
        default: {
            window.location.href = '/';
        }
    }
}

// function passwordResetConfirmFulfilled(state, status, responseData) {
//     switch (status) {
//         case 200: {
//             // console.log({...state})
//             return {...state}
//         }
//         case 401: {
//             // console.log(responseData)
//             return {...state, errors: responseData.errors, redirectUrl: "/"}
//         }
//         default: {
//             window.location.href = '/';
//         }
//     }
// }

function setNewPasswordFulfilled(state, status, responseData) {
    switch (status) {
        case 200: {
            return {...state, notifications: responseData.notifications, redirectUrl: "/"}
        }
        case 401: {
            return {...state, errors: responseData.errors, redirectUrl: "/"}
        }
        default: {
            window.location.href = '/';
        }
    }
}

const AuthRepository = (apiAdapter) => ({

    getCSRF: async () => {
        await apiAdapter.get(`/csrf/`)
    },
    signUp: async (signUpData, rejectWithValue) => {
        try {
            const response = await apiAdapter.post('/signup/', signUpData)
            return {data: response.data, status: response.status};
        } catch (error) {
            return rejectWithValue(handleError(error))
        }
    },
    signIn: async (signInData, rejectWithValue) => {
        try {
            const response = await apiAdapter.post('/login/', signInData)
            return {data: response.data, status: response.status};
        } catch (error) {
            return rejectWithValue(handleError(error))
        }
    },
    logOut: async (dispatch) => {
        await apiAdapter.get(`/logout/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, undefined);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },
    getVerificationCode: async (data, rejectWithValue) => {
        try {
            const response = await apiAdapter.post('/verification-code/', data)
            return {data: response.data, status: response.status};
        } catch (error) {
            return rejectWithValue(handleError(error))
            // return {data: {errors: handleError(error)}, status: error.response.status}
        }
    },
    getProfileData: async (dispatch, setData) => {
        await apiAdapter.get('/users/profile/')
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, setData);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },
    updateProfileData: async (data, dispatch) => {
        try {
            const response = await apiAdapter.post('/users/update-profile/', data)
            repositoryResponse(response.status, response.data, dispatch, undefined);
        } catch (error) {
            errorResponse(error.response.status, dispatch, error)
        }
    },
    // userEmailExist: async (email, dispatch) => {
    //     try {
    //         const response = await apiAdapter.post('/user-email-exist/', {email: email})
    //         repositoryResponse(response.status, response.data, dispatch, undefined);
    //     } catch (error) {
    //         errorResponse(error.response.status, dispatch, error)
    //     }
    // },
    passwordReset: async (email, rejectWithValue) => {
        try {
            const response = await apiAdapter.post('/password-reset/', {
                email: email
            })
            return {data: response.data, status: response.status};
        } catch (error) {
            return rejectWithValue(handleError(error))
            // return {data: {errors: handleError(error)}, status: error.response.status}
        }
    },
    passwordResetConfirm: async (uidb64, token) => {
        try {
            const response = await apiAdapter.get(`/password-reset-confirm/${uidb64}/${token}/`)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {data: {errors: handleError(error)}, status: error.response.status}
        }
    },
    setNewPassword: async (password1, password2, uidb64, token) => {
        try {
            const response = await apiAdapter.post(`/password-reset-confirm/${uidb64}/${token}/`, {
                new_password1: password1,
                new_password2: password2
            })
            return {data: response.data, status: response.status};
        } catch (error) {
            return {data: {errors: handleError(error)}, status: error.response.status}
        }
    }

});

export {
    AuthRepository,
    signUpInFulfilled,
    verificationFulfilled,
    // passwordResetConfirmFulfilled,
    setNewPasswordFulfilled
};