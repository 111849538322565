import {useForm, FormProvider} from "react-hook-form";

import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {login, setLoading} from "../../../Reducers/Auth2"
// import {reset as resetErrors} from "../../../Reducers/Errors"
import AuthContext from "../../../Components/AuthContext";

import '@fortawesome/fontawesome-svg-core/styles.css'

import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from '../../../hooks/useRecaptcha';

import Footer from "../../../Components/Footer/Footer";
import BrandNav from "../BrandNav";
import AuthBody from "../AuthBody";
import PassInput from "../PassInput";
import ErrorMessage from "../../../Components/Errors/ErrorMessage";
import BorderedSpinner from "../../../Components/Spinner/Spinner";
import {CSRF} from "../repository"

const reCaptchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY;

const LoginContent = () => {

    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.Auth2.loading)

    const methods = useForm()
    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors}
    } = methods

    const onSubmitHandler = (formValues) => {

        if (capchaToken) {
            dispatch(setLoading(true))
            dispatch(login(formValues));
        }
        // Reset captcha after submission
        recaptchaRef.current?.reset();
    }

    const {capchaToken, recaptchaRef, handleRecaptcha} = useRecaptcha();
    useEffect(() => {
        setValue("capcha_token", capchaToken)
        register("capcha_token", {required: true})
    }, [capchaToken]);


    return (
        <div className="auth-content py-md-0 py-8">
            {isLoading ?
                <div className="row mb-5">
                    <div className="col-lg-10 mx-auto d-flex position-absolute justify-content-center z-index-10">
                        <BorderedSpinner/>
                    </div>
                </div> : <></>
            }
            <FormProvider {...methods}>
                <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                    <fieldset disabled={isLoading}>
                        <div className="row mb-5">
                            <div className="col-lg-10 mx-auto">
                                <h4 className="mb-4">Увійдіть у свій обліковий запис</h4>
                                <div className="row gx-3">
                                    <div className="form-group col-lg-12">
                                        <div className="form-label-group">
                                            <label>Ім'я користувача</label>
                                        </div>
                                        <input className={"form-control"}
                                               placeholder="user@example.com"
                                               autoComplete={"on"}
                                               id="username"
                                               {...register("username", {required: true})}
                                               type="email"/>
                                        <ErrorMessage field={"Логін"} type={errors["username"]?.type}/>
                                    </div>
                                    {/*password*/}
                                    <PassInput label="Пароль" name="password" forgotButton={true}
                                               forgotLink="/reset-password"/>
                                </div>
                                <button type="submit" className="btn btn-primary btn-uppercase btn-block" name="submit"
                                        disabled={!capchaToken}>
                                    Увійти
                                </button>
                                <p className="p-xs mt-2 text-center">
                                    <a className="text-dark-60" href="/signup">Новий користувач?&nbsp;&nbsp;
                                        <u className="text-primary">Створити обліковий запис</u>
                                    </a>
                                </p>
                            </div>
                        </div>
                        {isLoading ? <></> :
                            <div className="row">
                                <div className="col-lg-10 mx-auto d-flex justify-content-center">
                                    <ReCAPTCHA ref={recaptchaRef}
                                               hl={'uk'}
                                               sitekey={reCaptchaKey}
                                               onChange={handleRecaptcha}
                                               theme={'light'}/>
                                </div>
                            </div>
                        }
                    </fieldset>
                </form>
            </FormProvider>
        </div>
    )
}

const Login = () => {

    let {isAuthenticated} = useContext(AuthContext)
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated === true)
            navigate('/', {replace: true});
        CSRF().then();
    }, [isAuthenticated, navigate]);

    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>

            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <LoginContent/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default Login

