const ErrorMessage = ({field, type}) => {

    switch (type) {
        case "required": {
            return (
                <span className="ms-2 text-red">
                    {`${field} не заповнено`}
                </span>
            )
        }
        case "notValid":{
            return (
                <span className="ms-2 text-red">
                    {`${field} заповнено некоректно`}
                </span>
            )
        }
        default: return null
    }
}

export default ErrorMessage