import {repositoryResponse, errorResponse} from "./Response";


const NewsRepository = (apiAdapter) => ({

    getArticle: async (slug, dispatch, setData) => {
        await apiAdapter.get(`/news/article/${slug}/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, setData);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },
    getAminArticle: async (slug, dispatch, setData) => {
        await apiAdapter.get(`/news/admin/article/${slug}/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, setData);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },
    addArticle: async (data, dispatch) => {

        const headers = {"Content-Type": "multipart/form-data"}

        try {
            const response = await apiAdapter.post('/news/admin/article/add/', data, headers)
            repositoryResponse(response.status, response.data, dispatch, undefined);
        } catch (error) {
            errorResponse(error.response.status, dispatch, error)
        }
    },
    updateArticle: async (slug, data, dispatch) => {

        const headers = {"Content-Type": "multipart/form-data"}

        try {
            const response = await apiAdapter.post(`/news/admin/article/update/${slug}/`, data, headers)
            repositoryResponse(response.status, response.data, dispatch, undefined);
        } catch (error) {
            errorResponse(error.response.status, dispatch, error)
        }
    },

    deleteArticle: async (slug, dispatch) => {
        await apiAdapter.delete(`/news/admin/article/delete/${slug}/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, undefined);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },

    updateArticleStatus: async (slug, data, dispatch) => {
        try {
            const response = await apiAdapter.post(`/news/admin/article/status/${slug}/`, data)
            repositoryResponse(response.status, response.data, dispatch, undefined);
        } catch (error) {
            // console.log(error)
            // return {errors: handleError(error)}
            errorResponse(error.response.status, dispatch, error)
        }
    }

});

export default NewsRepository;