import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ControlPanelButton = ({text, icon, onClick}) => {

    return (
        <button type="button" className="btn btn-outline-light btn-sm me-2"
                onClick={onClick}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={icon} className="text-primary my-1"/>
                        </span>
                    </span>
                    {text ? <span className="btn-text">{text}</span> : <></>}
                </span>
        </button>
    )
}

export default ControlPanelButton