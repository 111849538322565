import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import {reportError} from "../Utils/Errors";
import {
    AuthRepository,
    signUpInFulfilled,
    verificationFulfilled,
    // passwordResetConfirmFulfilled,
    setNewPasswordFulfilled
} from "../Repositories/AuthRepository";
import ApiAdapter from "../Adapters/ApiAdapter";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const initialState = {

    isAuthenticated: localStorage.getItem('isAuthenticated') === "true",
    isAdmin: false,
    redirectUrl: undefined,
    verificationCodeSent: false,
    passwordResetDone: false,
    loading: false,
    notifications: [],

}

const locale = "uk_UA"
const apiAdapter = ApiAdapter();

export const session = createAsyncThunk('Auth/session',
    async () => {
        // console.log('session')
        const response = await axios.get(`${baseUrl}/session/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken")
            },
            withCredentials: true,
        });
        return response.data;
    }
)

export const login = createAsyncThunk('Auth/login',
    async (signInData, {rejectWithValue}) => {

        const {signIn} = AuthRepository(apiAdapter);
        return await signIn(signInData, rejectWithValue).then()

    }
)

export const signup = createAsyncThunk('Auth/register',
    async (signUpData,{rejectWithValue}) => {

        // if signUpData
        const {signUp} = AuthRepository(apiAdapter);
        return await signUp(signUpData, rejectWithValue).then()
    }
)

export const getVerificationCode = createAsyncThunk('Auth/getVerificationCode',
    async (data, {rejectWithValue}) => {
        const {getVerificationCode} = AuthRepository(apiAdapter);
        return await getVerificationCode(data, rejectWithValue)
    }
)

export const passwordReset = createAsyncThunk('Auth/passwordReset',
    async ({email}) => {
        const {passwordReset} = AuthRepository(apiAdapter);
        return await passwordReset(email)
    }
)

export const passwordResetConfirm = createAsyncThunk('Auth/passwordResetConfirm',
    async ({uidb64, token}) => {
        const {passwordResetConfirm} = AuthRepository(apiAdapter);
        return await passwordResetConfirm(uidb64, token)
    }
)

export const setNewPassword = createAsyncThunk('Auth/setNewPassword',
    async ({password1, password2, uidb64, token}) => {
        const {setNewPassword} = AuthRepository(apiAdapter);
        return await setNewPassword(password1, password2, uidb64, token)
    }
)

export const Auth = createSlice({
    name: 'Auth2',
    initialState,
    reducers: {
        reset: () => {
            localStorage.clear();
            return initialState
        },
        setLogout: (state, action) => {
            localStorage.clear();
            return {...state, isAuthenticated: false, isAdmin: false}
        },
        setLoading: (state, action) => {
            return {...state, loading: action.payload}
        },
    },
    extraReducers: (builder) => {
        builder
            //--- LOGIN
            .addCase(login.fulfilled, (state, action) => {
                return signUpInFulfilled(state, action.payload.status, action.payload.data)
            })
            .addCase(login.rejected, (state, action) => {
                localStorage.clear();
                return {...state, loading: false, isAuthenticated: false}
            })
            //--- REGISTER
            .addCase(signup.fulfilled, (state, action) => {
                return signUpInFulfilled(state, action.payload.status, action.payload.data)
            })
            .addCase(signup.rejected, (state, action) => {
                return {...state, loading: false, verificationCodeSent: false}
            })
            //--- GET VERIFICATION CODE
            .addCase(getVerificationCode.fulfilled, (state, action) => {
                return verificationFulfilled(state, action.payload.status, action.payload.data)
            })
            .addCase(getVerificationCode.rejected, (state, action) => {
                return {...state, loading: false, verificationCodeSent: false}
            })
            //--- RESET PASSWORD
            .addCase(passwordReset.fulfilled, (state, action) => {
                return {...state, passwordResetDone: true}
            })
            .addCase(passwordReset.rejected, (state, action) => {
                return {...state, passwordResetDone: false}
            })
            // --- PASSWORD RESET CONFIRM
            // .addCase(passwordResetConfirm.fulfilled, (state, action) => {
            //     return passwordResetConfirmFulfilled(state, action.payload.status, action.payload.data)
            // })
            // .addCase(passwordResetConfirm.rejected, (state, action) => {
            //     return {...state, errors: reportError("OperationErrorTryLater", locale)}
            // })
            // SET NEW PASSWORD
            .addCase(setNewPassword.fulfilled, (state, action) => {
                return setNewPasswordFulfilled(state, action.payload.status, action.payload.data)
            })
            .addCase(setNewPassword.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
            // SESSION
            .addCase(session.fulfilled, (state, action) => {
                localStorage.setItem('isAuthenticated', action.payload.isAuthenticated);
                return {...state, isAuthenticated: action.payload.isAuthenticated, isAdmin: action.payload.isAdmin}
            })
            .addCase(session.rejected, (state, action) => {
                localStorage.clear();
                return {...state, isAuthenticated: false, isAdmin: false}
            })

    }
})

export const {
    reset,
    setLogout,
    setLoading
} = Auth.actions
export default Auth.reducer