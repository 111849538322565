import BrandNav from "../../Pages/Authentication/BrandNav";
import AuthBody from "../../Pages/Authentication/AuthBody";
import Footer from "../../Components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {passwordResetConfirm, setNewPassword} from "../../Reducers/Auth2";
import PassInput from "./PassInput";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {ListGroup} from "react-bootstrap";
import {validatePass} from "../../Utils/utils";
import {setErrors} from "../../Reducers/Errors";
import {useEffect} from "react";


const ResetPasswordContent = ({uidb64, token}) => {

    const dispatch = useDispatch();

    const methods = useForm()
    const {
        // register,
        setError,
        handleSubmit,
        formState: {errors}
    } = methods

    const onSubmitHandler = (formValues) => {

        const passErrors = validatePass(formValues.password1, formValues.password2)

        if (passErrors.length > 0) {
            dispatch(setErrors(passErrors))
            setError("password1", {type: "notValid"}, {shouldFocus: true})
            setError("password2", {type: "notValid"}, {shouldFocus: true})
        } else {
            dispatch(setNewPassword({
                uidb64: uidb64,
                token: token,
                password1: formValues.password1,
                password2: formValues.password2
            }))
        }
    }

    return (
        <div className="auth-content py-8">
            <FormProvider {...methods}>
                <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                        <div className="mx-auto col-lg-10 col-md-10 col-sm-10">
                            <div className="card-flush card">
                                <div className="text-center card-body">
                                    <h4>Відновити пароль</h4>
                                    <p className="mb-4">Скидання забутого пароля облікового запису кабінету
                                        електронних послуг
                                    </p>
                                    <div className="gx-3 row">
                                        {/*password*/}
                                        <PassInput label="Новий пароль" name="password1"/>
                                        {/*password2*/}
                                        <PassInput label="Новий пароль (підтвердження)" name="password2"/>
                                    </div>
                                    <div className="row gx-3 pe-0 mb-3 text-start">
                                        <ListGroup as="ol" variant="flush" className="border-0">
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Пароль не може бути надто схожим на іншу особисту інформацію.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Ваш пароль повинен містити як мінімум 8 символів
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Пароль не може бути одним із дуже поширених.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Пароль не може складається лише із цифр.
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <button type="submit"
                                            className="btn-uppercase btn-block btn btn-primary">Надіслати
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}


const PasswordResetConfirm = () => {

    const {uidb64, token} = useParams();

    const matchUidb64 = /^[A-Za-z]{3,9}$/.test(uidb64)
    const matchToken = /^[A-Za-z0-9]{6}-[A-Za-z0-9]{32,39}$/.test(token)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const redirectUrl = useSelector(state => state.Auth2.redirectUrl)

    useEffect(() => {
        if (redirectUrl)
            setTimeout(() => navigate(redirectUrl), 1000)
            // navigate(redirectUrl)
        else
            dispatch(passwordResetConfirm({uidb64: uidb64, token: token}))
    }, [redirectUrl, uidb64, token]);

    if (!matchUidb64 || !matchToken)
        return <Navigate to="/404" replace/>;

    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>
            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <ResetPasswordContent uidb64={uidb64} token={token}/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default PasswordResetConfirm